import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.oneFile.type == 'file' ? _c('div', {
    staticClass: "tfile"
  }, [_vm.$_isImage(_vm.oneFile.name) ? _c('span', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.$modal.show('FilePreview', {
          file: _vm.oneFile,
          fileidx: _vm.oneFileIdx
        });
      }
    }
  }, [_vm.oneFile.size < 500000 ? _c('span', [_c('img', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: {
        content: _vm.makeImageM(_vm.oneFile),
        placement: 'right-end',
        offset: 10,
        delay: {
          show: 200,
          hide: 300
        }
      },
      expression: "{content: makeImageM(oneFile),placement: 'right-end', offset:10, delay: {show: 200,hide: 300}}"
    }],
    staticClass: "thumbnail",
    attrs: {
      "src": _vm.$_getImgUrl(_vm.oneFile.path, 't')
    }
  })]) : _c('span', {
    staticClass: "ic"
  }, [_c('i', {
    staticClass: "icon-image marg"
  })])]) : _c('span', {
    staticClass: "ic",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.$modal.show('FilePreview', {
          file: _vm.oneFile,
          fileidx: _vm.oneFileIdx
        });
      }
    }
  }, [_c('i', {
    staticClass: "icon-file marg"
  })]), _c('span', {
    staticClass: "mulitline",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.$modal.show('FilePreview', {
          file: _vm.oneFile,
          fileidx: _vm.oneFileIdx
        });
      }
    }
  }, [_c('div', {
    staticClass: "block"
  }, [_c('div', {
    staticClass: "caption"
  }, [_vm._v(_vm._s(_vm.oneFile.name))])]), _c('div', {
    staticClass: "block"
  }, [_c('div', {
    staticClass: "modified"
  }, [_vm._v(_vm._s(_vm.$dayjs(_vm.oneFile.atimeMs).format('HH:mm DD.MM.YY')) + "  ")]), _c('div', {
    staticClass: "size"
  }, [_vm._v("(" + _vm._s(_vm.$_toHumanFileSize(_vm.oneFile.size)) + ")")])])]), _c('span', {
    staticClass: "iconblock"
  }, [_vm.$_isImage(_vm.oneFile.name) ? _c('a', {
    staticClass: "ic",
    attrs: {
      "href": "",
      "target": "_blank"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.$_clickToDownload(_vm.oneFile.path);
      }
    }
  }, [_c('i', {
    staticClass: "icon-link"
  })]) : _c('a', {
    staticClass: "ic",
    attrs: {
      "href": _vm.$_getImgUrl(_vm.oneFile.path, "path"),
      "target": "_blank"
    }
  }, [_c('i', {
    staticClass: "icon-link"
  })]), _c('span', {
    staticClass: "ic",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.askDeleteFile(_vm.oneFile);
      }
    }
  }, [_c('i', {
    staticClass: "icon-trashbin"
  })])])]) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };