import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "dateien"
  }, [_vm.view == 'mini' ? _c('span', [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header"
  }, [_c('h4', [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.ministate = 'pics';
      }
    }
  }, [_vm._v("Bilder")]), _c('span', [_vm._v("      ")]), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.ministate = 'docs';
      }
    }
  }, [_vm._v("Dokumente")]), _c('span', [_vm._v("      ")]), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.ministate = 'hvw';
      }
    }
  }, [_vm._v("HVW")])]), _c('div', {
    staticClass: "FotoMaker fr"
  }, [_c('span', {
    staticClass: "_redp link bold icfolder",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.GetAllFiles();
      }
    }
  }, [_c('i', {
    staticClass: "icon-download _bluep lrg"
  }), _c('span', {
    staticClass: "_bluep"
  }, [_vm._v(" Neu laden")])]), _c('span', [_vm._v("   ")])]), _c('div', {
    staticClass: "clear"
  })]), _vm.ministate == 'pics' ? _c('div', {
    staticClass: "card-content"
  }, [_c('div', {
    staticClass: "ovauto"
  }, [_vm.showEmbedImage ? _c('ul', {
    staticClass: "files_mini ovauto"
  }) : _vm._e(), _c('ul', {
    staticClass: "files_mini ovauto"
  }, _vm._l(_vm.imagesDir, function (item, idx) {
    return _vm.imagesDir.length > 0 && _vm.restrictFiles(item, true) ? _c('li', [_c('div', {
      staticClass: "link",
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          return _vm.OpenThisFile(item, idx);
        }
      }
    }, [_c('div', {
      staticClass: "embimg _small",
      style: 'background-image:url("' + _vm.$_getImgUrl(item.path, 't') + '")'
    }), item.name.length > 8 ? _c('div', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: {
          content: item.name,
          placement: 'right-end',
          offset: 10,
          delay: {
            show: 200,
            hide: 300
          }
        },
        expression: "{content: item.name ,placement: 'right-end', offset:10, delay: {show: 200,hide: 300}}"
      }],
      staticClass: "caption"
    }, [_vm._v(" " + _vm._s(item.name.substring(0, 6)) + "…")]) : _c('div', {
      staticClass: "caption"
    }, [_vm._v(_vm._s(item.name))])])]) : _vm._e();
  }), 0)])]) : _vm._e(), _vm.ministate == 'docs' ? _c('div', {
    staticClass: "card-content"
  }, [_c('div', {
    staticClass: "ovauto"
  }, [_vm.showEmbedImage ? _c('ul', {
    staticClass: "files_mini ovauto"
  }) : _vm._e(), _c('ul', {
    staticClass: "files_mini ovauto"
  }, _vm._l(_vm.docDir, function (item, idx) {
    return _vm.imagesDir.length > 0 && _vm.restrictFiles(item, true) ? _c('li', [_c('div', {
      staticClass: "link",
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          return _vm.OpenThisFile(item, idx);
        }
      }
    }, [_vm.$_isImage(item.name) ? _c('div', {
      staticClass: "embimg _small"
    }, [item.size < 500000 ? _c('span', [_c('img', {
      staticClass: "thumbnail",
      attrs: {
        "src": _vm.$_getImgUrl(item.path, 't')
      }
    })]) : _c('span', {
      staticClass: "ic"
    }, [_c('i', {
      staticClass: "icon-image verylarge"
    })])]) : _c('div', {
      staticClass: "embimg _small ic"
    }, [_c('i', {
      staticClass: "icon-file verylarge"
    })]), item.name.length > 8 ? _c('div', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: {
          content: item.name,
          placement: 'right-end',
          offset: 10,
          delay: {
            show: 200,
            hide: 300
          }
        },
        expression: "{content: item.name ,placement: 'right-end', offset:10, delay: {show: 200,hide: 300}}"
      }],
      staticClass: "caption"
    }, [_vm._v(" " + _vm._s(item.name.substring(0, 6)) + "…")]) : _c('div', {
      staticClass: "caption"
    }, [_vm._v(_vm._s(item.name))])])]) : _vm._e();
  }), 0)])]) : _vm._e(), _vm.ministate == 'hvw' ? _c('div', {
    staticClass: "card-content"
  }, [_c('div', {
    staticClass: "ovauto"
  }, [_vm.showEmbedImage ? _c('ul', {
    staticClass: "files_mini ovauto"
  }) : _vm._e(), _c('ul', {
    staticClass: "files_mini ovauto"
  }, _vm._l(_vm.hvwDir, function (item, idx) {
    return _vm.imagesDir.length > 0 && _vm.restrictFiles(item, true) ? _c('li', [_c('div', {
      staticClass: "link",
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          return _vm.OpenThisFile(item, idx);
        }
      }
    }, [_vm.$_isImage(item.name) ? _c('div', {
      staticClass: "embimg _small"
    }, [item.size < 500000 ? _c('span', [_c('img', {
      staticClass: "thumbnail",
      attrs: {
        "src": _vm.$_getImgUrl(item.path, 't')
      }
    })]) : _c('span', {
      staticClass: "ic"
    }, [_c('i', {
      staticClass: "icon-image verylarge"
    })])]) : _c('div', {
      staticClass: "embimg _small ic"
    }, [_c('i', {
      staticClass: "icon-file verylarge"
    })]), item.name.length > 8 ? _c('div', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: {
          content: item.name,
          placement: 'right-end',
          offset: 10,
          delay: {
            show: 200,
            hide: 300
          }
        },
        expression: "{content: item.name ,placement: 'right-end', offset:10, delay: {show: 200,hide: 300}}"
      }],
      staticClass: "caption"
    }, [_vm._v(" " + _vm._s(item.name.substring(0, 6)) + "…")]) : _c('div', {
      staticClass: "caption"
    }, [_vm._v(_vm._s(item.name))])])]) : _vm._e();
  }), 0)])]) : _vm._e()])]) : _c('span', [_vm.$_size(_vm.filesNotUploadedYet) > 0 ? _c('div', {
    staticClass: "card"
  }, [_vm._m(0), _c('div', {
    staticClass: "card-content"
  }, [_c('div', {
    staticClass: "clear"
  }), _vm.isInternet ? _c('span', [_c('div', {
    staticClass: "btn _medium _green",
    on: {
      "click": _vm.UploadeNotReadyFiels
    }
  }, [_vm._v(_vm._s(_vm.filesNotUploadedYet.length) + " Offline Datei(en) hochladen")])]) : !_vm.isInternet ? _c('span', [_c('div', {
    staticClass: "btn _medium _green",
    on: {
      "click": _vm.UploadeNotReadyFiels
    }
  }, [_vm._v(_vm._s(_vm.filesNotUploadedYet.length) + " Offline Datei(en) ansehen")]), _c('span', {
    staticClass: "bold color-red"
  }, [_vm._v("Bei Internet Verbindung können diese Daten später hochgeladen werden!")])]) : _vm._e(), _c('div', {
    staticClass: "fr"
  })])]) : _vm._e(), _c('div', {
    staticClass: "card _less"
  }, [_c('div', {
    staticClass: "btn _small _blue",
    on: {
      "click": function click($event) {
        !_vm.isDownloadingZip && _vm.askZipDownload();
      }
    }
  }, [_vm._v("Zip-Datei erstellen")]), _vm._isAdmin ? _c('span', [_c('div', {
    staticClass: "btn _small _red",
    on: {
      "click": function click($event) {
        !_vm.isRimRaffing && _vm.askRimrafProject();
      }
    }
  }, [_vm._v("Ordner Löschen!")]), _vm._isGf ? _c('span', [_c('div', {
    staticClass: "btn _small _bordered",
    on: {
      "click": function click($event) {
        !_vm.isCompressing && _vm.restoreReportOnly();
      }
    }
  }, [_vm._v("Bewertung wiederherstellen")])]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "btn _small _bordered",
    on: {
      "click": function click($event) {
        !_vm.isCompressing && _vm.restoreFilesFromSp();
      }
    }
  }, [_vm._v("Daten wiederherstellen")]), _c('div', {
    staticClass: "fr btn _small",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.$modal.show('PhotoUpload');
      }
    }
  }, [_vm._v("Camera")]), _vm.zipLink && !_vm.isDownloadingZip ? _c('div', [_c('a', {
    staticClass: "link bold",
    attrs: {
      "href": _vm.zipLink
    }
  }, [_vm._v("Zip runterladen")])]) : _vm._e(), _vm.isCompressing ? _c('div', [_c('div', {
    staticClass: "loading-spinner _small"
  }), _c('span', [_vm._v(" Dateien werden wiederhergestellt! Bitte um etwas Geduld! (" + _vm._s(_vm.$_toHumanFileSize(_vm.totalFileSize)) + ")")])]) : _vm._e(), _vm.isDownloadingZip ? _c('div', [_c('div', {
    staticClass: "loading-spinner _small"
  }), _c('span', [_vm._v(" Zip Datei wird erstellt, bitte um Geduld, das kann je nach Größe etwas Zeit brauchen!")])]) : _vm._e(), _vm.isSavingToSp ? _c('div', [_c('div', {
    staticClass: "loading-spinner _small"
  }), _c('span', [_vm._v(" Dateien werden zum Sharepoint geladen")])]) : _vm._e(), _vm.$_size(_vm.filesFromSp) > 0 || _vm.totalFileSize > 0 ? _c('div', {
    staticClass: "padding tac bold"
  }, [_c('div', {
    staticClass: "padding"
  }, [_vm._v("Es befinden sich " + _vm._s(_vm.$_size(_vm.filesFromSp)) + " Dateien im Archiv. Das Archiv ist " + _vm._s(_vm.$_toHumanFileSize(_vm.totalFileSize)) + " groß. Bitte um etwas Geduld beim Download!")]), _c('div', {
    staticClass: "btn _medium _green",
    on: {
      "click": function click($event) {
        !_vm.isCompressing && _vm.restoreFilesFromSp();
      }
    }
  }, [_vm._v("Daten wiederherstellen")])]) : _vm._e()]), _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header noselect"
  }, [_c('h4', [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.showFiles = !_vm.showFiles;
      }
    }
  }, [_vm._v("Dateien")])])]), _vm.showFiles ? _c('div', {
    staticClass: "card-content"
  }, [_c('div', {
    staticClass: "ovauto"
  }, [_vm.view == 'tree' ? _c('div', {
    staticClass: "wz-tabs"
  }, [_c('div', {
    staticClass: "wz-tab",
    class: {
      _selected: _vm.view_LOC == 'files'
    },
    on: {
      "click": function click($event) {
        _vm.view_LOC = "files";
      }
    }
  }, [_vm._v("Bilder Ansicht")]), _c('div', {
    staticClass: "wz-tab",
    class: {
      _selected: _vm.view_LOC == 'tree'
    },
    on: {
      "click": function click($event) {
        _vm.view_LOC = "tree";
      }
    }
  }, [_vm._v("Baum-Ansicht")]), _c('div', {
    staticClass: "wz-tab",
    on: {
      "click": function click($event) {
        return _vm.GetAllFiles();
      }
    }
  }, [_c('div', {
    staticClass: "_redp"
  }, [_vm._v("Neu Laden")])])]) : _vm._e()]), _vm.view == 'files' || _vm.view_LOC == 'files' ? _c('div', {
    staticClass: "ovauto"
  }, [_c('div', {
    staticClass: "clear"
  }), _vm.showEmbedImage ? _c('ul', {
    staticClass: "files ovauto"
  }, [_vm._l(_vm.imagesDir, function (item, idx) {
    return _vm.imagesDir.length > 0 && _vm.restrictFiles(item, true) ? _c('li', [_c('div', {
      staticClass: "link",
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          return _vm.OpenThisFile(item, idx);
        }
      }
    }, [_c('div', {
      staticClass: "embimg _small",
      style: 'background-image:url("' + _vm.$_getImgUrl(item.path, 't') + '")'
    }), _c('div', {
      staticClass: "caption"
    }, [_vm._v(_vm._s(item.name))])])]) : _vm._e();
  }), _vm.imagesDir.length == 0 ? _c('li', [_vm._v("keine Bilder vorhanden!")]) : _vm._e()], 2) : _vm._e()]) : _vm.view_LOC == 'tree' ? _c('div', {
    staticClass: "ovauto2"
  }, [!_vm.isInternet ? _c('div', {
    staticClass: "padding tac"
  }, [_c('h4', {
    staticClass: "color-red"
  }, [_vm._v("Keine Internet Verbindung")]), _c('p', {
    staticClass: "color-red"
  }, [_vm._v("Daten können nicht angezeigt werden!")]), _c('p', [_vm._v("jedoch können Bilder offline gespeichert werden.")]), _c('div', {
    staticClass: "btn",
    on: {
      "click": function click($event) {
        return _vm.$modal.show('PhotoUpload');
      }
    }
  }, [_vm._v("Bilder offline speichern")])]) : _vm._e(), _vm.showEmbedImage && _vm.allfiles ? _c('ul', {
    staticClass: "noselect"
  }, _vm._l(_vm.allfiles.children, function (item, idx) {
    return _vm.restrictFiles(item) ? _c('li', {
      staticClass: "nomarg"
    }, [item.type == 'directory' && item.children ? _c('ul', {
      staticClass: "treeview"
    }, [_c('li', {
      staticClass: "nomarg"
    }, [_c('onefolderAllianz', {
      on: {
        "emited": _vm.updateFolder
      },
      model: {
        value: _vm.allfiles.children[idx],
        callback: function callback($$v) {
          _vm.$set(_vm.allfiles.children, idx, $$v);
        },
        expression: "allfiles.children[idx]"
      }
    })], 1), _vm._l(item.children, function (item1, idx1) {
      return _vm.restrictFiles(item1) && !item.closed ? _c('li', [item1.type == 'directory' && item1.children ? _c('ul', [_c('li', {
        staticClass: "nomarg"
      }, [_c('onefolderAllianz', {
        on: {
          "emited": _vm.updateFolder
        },
        model: {
          value: _vm.allfiles.children[idx].children[idx1],
          callback: function callback($$v) {
            _vm.$set(_vm.allfiles.children[idx].children, idx1, $$v);
          },
          expression: "allfiles.children[idx].children[idx1]"
        }
      })], 1)]) : _vm._e(), _c('onefileAllianz', {
        attrs: {
          "oneFile": item1,
          "oneFileIdx": idx1,
          "currentProjectUid": _vm.currentProjectUid
        },
        on: {
          "reload": function reload($event) {
            return _vm.GetAllFiles();
          }
        }
      })], 1) : _vm._e();
    })], 2) : _vm._e(), _c('onefileAllianz', {
      attrs: {
        "oneFile": item,
        "oneFileIdx": idx,
        "currentProjectUid": _vm.currentProjectUid
      },
      on: {
        "reload": function reload($event) {
          return _vm.GetAllFiles();
        }
      }
    })], 1) : _vm._e();
  }), 0) : _vm._e()]) : _vm._e()]) : _vm._e()]), _vm._m(1)]), _c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "name": "FilesToUpload",
      "adaptive": true,
      "draggable": false,
      "scrollable": true,
      "resizable": true,
      "reset": true,
      "width": "640",
      "height": "auto"
    },
    on: {
      "before-open": _vm.beforeOpenFilesToUpload
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("Dateien Upload"), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('FilesToUpload');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times inline"
  })])]), _vm.touploadfiles ? _c('div', {
    staticClass: "block"
  }, [_c('div', {
    staticClass: "folder bold"
  }, [_vm._v("Hochladen in :")]), _c('br'), _c('VueSelect', {
    attrs: {
      "label": "name",
      "options": _vm.folderInProject
    },
    model: {
      value: _vm.toUploadFolder,
      callback: function callback($$v) {
        _vm.toUploadFolder = $$v;
      },
      expression: "toUploadFolder"
    }
  }), _vm.toUploadFolder == 'Bilder/' ? _c('div', {
    staticClass: "padding"
  }, [_c('small', [_vm._v("* Bitte für den 'Bilder' Ordner ausschließlich .jpg/.jpeg/.JPG oder .png Dateien benutzen. Andere Ordner können auch andere Bildformate beeinhalten!")])]) : _vm._e(), _c('br'), _c('input', {
    attrs: {
      "id": "avatar",
      "type": "file",
      "name": "avatar",
      "accept": "image/png, image/jpeg, application/pdf",
      "multiple": "",
      "capture": ""
    },
    on: {
      "change": _vm.addFiles
    }
  }), _c('h5', [_vm._v("Folgende Dateien werden raufgeladen:")]), _c('ul', {
    staticClass: "touploadfiles"
  }, _vm._l(_vm.touploadfiles, function (file) {
    return _c('li', [_c('div', {
      staticClass: "filenamename"
    }, [_vm._v(_vm._s(file.name) + "(" + _vm._s(_vm.$_toHumanFileSize(file.size, true)) + ")")]), _vm._l(_vm.toUploadFolder.children, function (ofile) {
      return file.name == ofile.name ? _c('div', {
        staticClass: "override"
      }, [_vm._v("WIRD ÜBERSCHRIEBEN")]) : _vm._e();
    })], 2);
  }), 0)], 1) : _vm._e(), _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    staticClass: "btn _med _red",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('FilesToUpload');
      }
    }
  }, [_vm._v("Abbrechen")]), _c('div', {
    staticClass: "btn _med _green",
    on: {
      "click": function click($event) {
        return _vm.uploadGivenFiles();
      }
    }
  }, [_vm._v("Daten hochladen")])])]), _c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "name": "FilesOfflineToUpload",
      "adaptive": true,
      "draggable": false,
      "scrollable": true,
      "resizable": true,
      "reset": true,
      "width": "640",
      "height": "auto"
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("Dateien Upload"), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('FilesOfflineToUpload');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times inline"
  })])]), _vm.touploadfiles ? _c('div', {
    staticClass: "block"
  }, [_vm.$_size(_vm.filesNotUploadedYet) > 0 ? _c('div', {
    staticClass: "padding"
  }, [_c('h5', [_vm._v("Offline gespeicherte Daten:")]), _c('div', {
    staticClass: "padding"
  }, [_c('small', [_vm._v("* Bitte für den 'Bilder' Ordner ausschließlich .jpg/.jpeg/.JPG oder .png Dateien benutzen. Andere Ordner können auch andere Bildformate beeinhalten!")])]), _c('ul', {
    staticClass: "touploadfiles"
  }, _vm._l(_vm.filesNotUploadedYet, function (file, fileIdx) {
    return _c('li', [_c('v-checkbox', {
      attrs: {
        "item": file.idbidx,
        "label": file.folder + '' + file.filename
      },
      model: {
        value: _vm.toUploadOflineFiles,
        callback: function callback($$v) {
          _vm.toUploadOflineFiles = $$v;
        },
        expression: "toUploadOflineFiles"
      }
    }), _c('div', {
      staticClass: "fr"
    }, [_c('div', {
      staticClass: "btn _small _red",
      on: {
        "click": function click($event) {
          return _vm.askCleanUpIDB([file.idbidx]);
        }
      }
    }, [_vm._v("Löschen")])])], 1);
  }), 0)]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    staticClass: "btn _med _red",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('FilesOfflineToUpload');
      }
    }
  }, [_vm._v("Abbrechen")]), _vm.isInternet ? _c('span', [_c('div', {
    staticClass: "btn _med _green",
    on: {
      "click": function click($event) {
        return _vm.uploadOfflineFiles();
      }
    }
  }, [_vm._v("Offline Daten hochladen")])]) : _vm._e()])]), _c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "name": "PhotoUpload",
      "adaptive": true,
      "draggable": false,
      "resizable": true,
      "reset": true,
      "width": "480",
      "height": "240"
    },
    on: {
      "before-open": _vm.beforeOpenPhotoUpload
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("PHOTO Upload"), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('PhotoUpload');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times inline"
  })])]), _vm.touploadfiles ? _c('div', {
    staticClass: "block"
  }, [_c('div', {
    staticClass: "folder bold"
  }, [_vm._v("Hochladen in : " + _vm._s(_vm.toUploadFolder))]), _c('input', {
    attrs: {
      "id": "allianz-auto-image",
      "type": "file",
      "accept": "image/*",
      "capture": ""
    },
    on: {
      "change": _vm.addPhotos
    }
  }), _c('div', {
    staticClass: "spacer _vertical _large"
  }), _c('div', {
    staticClass: "fr"
  }, [_c('div', {
    staticClass: "btn _med _red",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('PhotoUpload');
      }
    }
  }, [_vm._v("Abbrechen")])])]) : _vm._e()]), _c('modal', {
    staticClass: "noselect modal",
    attrs: {
      "id": "FilePreview",
      "name": "FilePreview",
      "escapeKeyToClose": true,
      "adaptive": true,
      "draggable": false,
      "scrollable": false,
      "reset": true,
      "width": "98%",
      "height": "98%",
      "maxHeight": 1280,
      "maxWidth": 1280
    },
    on: {
      "before-open": _vm.beforeOpenPreview
    }
  }, [_vm.previewFile ? _c('div', {
    staticClass: "block_iframe"
  }, [_c('div', {
    staticClass: "ovauto"
  }, [_c('div', {
    staticClass: "Clicker left",
    on: {
      "click": function click($event) {
        _vm.previewFileIDX--;
      }
    }
  }, [_c('i', {
    staticClass: "icon-arrow-left"
  })]), _c('div', {
    staticClass: "Clicker right",
    on: {
      "click": function click($event) {
        _vm.previewFileIDX++;
      }
    }
  }, [_c('i', {
    staticClass: "icon-arrow-right"
  })]), ['.webp', '.jpg', '.jpeg', '.png'].includes(_vm.previewFile.extension.toLowerCase()) ? _c('div', {
    staticClass: "embeded"
  }, [_c('Printer', {
    attrs: {
      "showimg": _vm.$_getImgUrl(_vm.previewFile.path, 'original'),
      "preview": _vm.$_getImgUrl(_vm.previewFile.path, 'c'),
      "rot": _vm.previewFile.name
    }
  })], 1) : _vm.previewFile.extension == '.pdf' || _vm.previewFile.extension == '.txt' ? _c('div', {
    staticClass: "embeded"
  }, [_c('embed', {
    attrs: {
      "src": _vm.$env.mainurl + _vm.previewFile.path + _vm.tok,
      "width": "100%",
      "height": "100%",
      "type": "application/pdf"
    }
  })]) : _c('div', {
    staticClass: "tac"
  }, [_c('h3', [_vm._v("Datei Format nicht unterstützt:")]), _c('br'), _c('pre', [_vm._v(_vm._s(_vm.previewFile))])])]), _vm.previewFile.extension == '.pdf' && ((_vm.mobile || {}).os || {}).name === 'Android' ? _c('div', {
    staticClass: "headlink"
  }, [_c('a', {
    attrs: {
      "href": _vm.$env.mainurl + _vm.previewFile.path + _vm.tok
    }
  }, [_vm._v("Download")])]) : _vm._e(), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('FilePreview');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times inline"
  })])]) : _vm._e()])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "card-header"
  }, [_c('h4', [_vm._v("Offline Speicher")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticStyle: {
      "visibility": "hidden",
      "opacity": "0"
    },
    attrs: {
      "id": "dropzone"
    }
  }, [_c('div', {
    attrs: {
      "id": "textnode"
    }
  }, [_c('h1', [_c('i', {
    staticClass: "icon-upload"
  })]), _vm._v("HIER Daten ablegen!")])]);
}];
export { render, staticRenderFns };