import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "tfolder",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.item.closed = !_vm.item.closed;
        _vm.$emit('emited', _vm.item);
      }
    }
  }, [_c('pre', [_vm._v(" ")]), _c('span', {
    staticClass: "icfolder"
  }, [_c('i', {
    class: !_vm.item.closed ? 'icon-folder-open' : 'icon-folder'
  })]), _c('span', {
    staticClass: "caption"
  }, [_vm._v(_vm._s(_vm.item.name) + " "), _c('span', {
    staticClass: "color-lightgrey"
  }, [_vm._v("(" + _vm._s(_vm.item.children.length) + ")")])]), _vm.item.name == 'Bilder' ? _c('span', {
    staticClass: "icfolder",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.$modal.show('PhotoUpload', {
          folder: _vm.item
        });
      }
    }
  }, [_c('i', {
    staticClass: "icon-camera"
  })]) : _vm._e(), _c('span', {
    staticClass: "icfolder",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.$modal.show('FilesToUpload', {
          folder: _vm.item
        });
      }
    }
  }, [_c('i', {
    staticClass: "icon-upload"
  })])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };