import _createForOfIteratorHelper from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _objectSpread from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
// This is a description of the component
import onefileAllianz from "./onefileAllianz";
import onefolderAllianz from "./onefolderAllianz";
import mixinDateien from "./mixinDateien";
import { mapFields } from "vuex-map-fields";
import Printer from "@/components/Printer";
import { mapState } from "vuex";
// let db;
// let dbVersion = 1;
// let dbReady = false;
var vu = null;
export default {
  name: "DateienAllianz",
  beforeCreate: function beforeCreate() {
    vu = this;
  },
  components: {
    onefileAllianz: onefileAllianz,
    onefolderAllianz: onefolderAllianz,
    Printer: Printer
  },
  mixins: [mixinDateien],
  data: function data() {
    onefileAllianz;
    return {
      innerwidth: 720,
      innerheight: 720,
      ministate: "pics",
      folderInProject: ["Bilder/", "Dokumente/", "HVW/"],
      showFiles: true,
      view_LOC: "tree",
      touploadfiles: [],
      filesNotUploadedYet: {},
      toUploadOflineFiles: [],
      triesToDownload: false,
      filesFromSp: {},
      totalFileSize: 0,
      zipLink: "",
      showBilder: true,
      UploadPercent: 0,
      allfiles: [],
      imagesDir: {},
      docDir: {},
      hvwDir: {},
      toChangeFile: null,
      previewFile: null,
      previewFileIDX: -1,
      previewFolder: "",
      toUploadFolder: "",
      // = root!
      showEmbedImage: true,
      // for embed
      isCompressing: false,
      isDownloadingZip: false,
      isSavingToSp: false,
      isRimRaffing: false,
      watchforchanges: false
    };
  },
  // The name of the form, up to 8 characters
  props: ["reload", "currentProjectUid", "view", "from"],
  computed: _objectSpread({}, mapState(["isInternet", "mobile"])),
  watch: {
    currentProjectUid: {
      immediate: true,
      handler: function handler() {
        // console.log("currentProjectUid  ----------–––––––––––––––––");
        vu.allfiles = {};
        vu.getOfflineImagesForThisId();
        vu.GetAllFiles();
      }
    },
    view: function view(nv) {
      vu.view_LOC = nv;
    },
    reload: function reload(nv) {
      if (nv) {
        vu.GetAllFiles();
      }
    },
    previewFileIDX: function previewFileIDX(nv) {
      var leng = 0;
      if (vu.previewFolder == "Bilder") leng = vu.imagesDir.length;else if (vu.previewFolder == "Dokumente") leng = vu.docDir.length;else if (vu.previewFolder == "HVW") leng = vu.hvwDir.length;

      // console.log(vu.docDir);

      if (nv >= leng) nv = 0;else if (nv < 0) nv = leng - 1;
      vu.previewFileIDX = nv;
      if (vu.previewFolder == "Bilder") {
        vu.previewFile = vu.imagesDir[nv];
      } else if (vu.previewFolder == "Dokumente") {
        vu.previewFile = vu.docDir[nv];
      } else if (vu.previewFolder == "HVW") {
        vu.previewFile = vu.hvwDir[nv];
      }
    }
  },
  /*************************************************
   mounted
   *************************************************/
  mounted: function mounted() {
    window.addEventListener("dragenter", function (e) {
      e.dataTransfer.dropEffect = "copy";
      e.dataTransfer.effectAllowed = "copy";
      var dropzone = document.getElementById("dropzone");
      var textnode = document.getElementById("textnode");
      dropzone.style.visibility = "";
      dropzone.style.opacity = "1";
      textnode.style.fontSize = "48px";
    });
    window.addEventListener("dragleave", function (e) {
      e.dataTransfer.dropEffect = "copy";
      e.dataTransfer.effectAllowed = "copy";
      e.preventDefault();
      dropzone.style.visibility = "hidden";
      dropzone.style.opacity = "0";
      textnode.style.fontSize = "42px";
    });
    window.addEventListener("dragover", function (e) {
      e.dataTransfer.dropEffect = "copy";
      e.dataTransfer.effectAllowed = "copy";
      e.preventDefault();
      dropzone.style.visibility = "";
      dropzone.style.opacity = "1";
      textnode.style.fontSize = "48px";
    });
    window.addEventListener("drop", function (e) {
      e.dataTransfer.dropEffect = "copy";
      e.dataTransfer.effectAllowed = "copy";
      e.preventDefault();
      dropzone.style.visibility = "hidden";
      dropzone.style.opacity = "0";
      textnode.style.fontSize = "42px";
      var files = e.dataTransfer.files;
      vu.touploadfiles = [];
      if (files.length > 0) vu.$modal.show("FilesToUpload");
      var _iterator = _createForOfIteratorHelper(files),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var i = _step.value;
          // window.LOG.log(i);
          vu.touploadfiles.push(i);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    });
  },
  /*************************************************
   methods
   *************************************************/
  methods: {
    beforeOpenChangeFileModal: function beforeOpenChangeFileModal(e) {
      vu.toChangeFile = e.params.file;
    },
    beforeOpenFilesToUpload: function beforeOpenFilesToUpload(e) {
      vu.touploadfiles = [];
      // console.log(((e.params || {}).folder || {}).name);
      if (((e.params || {}).folder || {}).name) {
        vu.toUploadFolder = e.params.folder.name + "/";
      } else {
        vu.toUploadFolder = "Bilder/";
      }
    },
    // Fire when the form is cleared
    // @arg The argument is a boolean value representing xxx
    beforeOpenPhotoUpload: function beforeOpenPhotoUpload(e) {
      vu.touploadfiles = [];
      vu.toUploadFolder = "Bilder/";
      setTimeout(function () {
        vu.openPhotoinput();
      }, 120);
    },
    openPhotoinput: function openPhotoinput() {
      document.getElementById("allianz-auto-image").click();
    },
    beforeOpenPreview: function beforeOpenPreview(e) {
      window.LOG.log("beforeOpenPreview", e.params.file);
      vu.previewFolder = e.params.file.path.split("/")[3];
      vu.previewFileIDX = e.params.fileidx;
      vu.previewFile = e.params.file;
      vu.toChangeFile = e.params.file; // QUICKFIX!!
      // console.log("---");
      // console.log(vu.previewFolder);
      // console.log(vu.previewFileIDX);
      // console.log("---");
    }
  }
};