var vu = null;
export default {
  beforeCreate: function beforeCreate() {
    vu = this;
  },
  data: function data() {
    return {};
  },
  props: ["item", "value"],
  model: {
    prop: "item",
    event: "UpdateEvent"
  },
  watch: {
    item: function item(nv) {
      window.LOG.log(nv);
    }
  },
  mounted: function mounted() {},
  methods: {}
};